import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../contexts/AccountContext';

const Head = () => {
	const { termsContracts } = useContext(AccountContext);
	let navigate = useNavigate();
	return (
		<div className="head">
			<div className="head-left-text">
				<div className="phone" onClick={() => navigate('/atendimento')}>
					<span className="material-symbols-outlined">call</span>
					<a href="tel:86 3025-0040"> (86) 3025-0040</a>
				</div>
				<div className="email" onClick={() => navigate('/atendimento')}>
					<span className="material-symbols-outlined">mail</span>
					<a href="mailto:contato@greatschool.com.br?subject=Contato Pensar Store">
						contato@escolaexpert.com.br
					</a>
				</div>
				<div className="atendimento" onClick={() => navigate('/atendimento')}>
					<span className='atendimento-nome'>
						Atendimento
					</span>
				</div>
			</div>
			<div className="head-right-text">
				<div className="perguntas" onClick={() => navigate('/faq')}>
					<label>F.A.Q.</label>
				</div>
				<div className="termos">
					<label>
						<a target="_blank" rel="noopener noreferrer" href={termsContracts}>
							<h3>Termos & Condições</h3>
						</a>
					</label>
				</div>
			</div>
		</div>
	);
};

export default Head;
