import { createContext, useState, useContext, useEffect } from 'react';
import { CartContext } from './CartContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from './GlobalContext';
import { AccountContext } from './AccountContext';
import creditCardType from 'credit-card-type';

export const PaymentContext = createContext(null);

export const PaymentProvider = ({ children }) => {
	// valores do paymentMethod
	const boleto = 'boleto';
	const credito = 'credito';

	const {
		BASE_URL,
		STORE_ID,
		carrinho,
		setCarrinho,
		setCarrinhoConclusaoInfo,
	} = useContext(GlobalContext);
	const { cartTotal, cartTotalParcelado } = useContext(CartContext);
	const { customerId, userToken, Toast, loggedUserData, tokenExpireLogout } =
		useContext(AccountContext);
	const [payment, setPayment] = useState({
		installments: '',
		cardNumber: '',
		holder: '',
		expirationDate: '',
		cvc: '',
		focus: '',
	});
	const [acceptedTerms, setAcceptedTerms] = useState('disabled');
	const [paymentMethod, setPaymentMethod] = useState(boleto);
	const [paymentMethodIds, setPaymentMethodIds] = useState('');
	const [paymentMethodId, setPaymentMethodId] = useState('');
	const [parcelas, setParcelas] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const cardNumberTreated = payment.cardNumber
		.split(' ')
		.join('')
		.replaceAll('_', '');

	const expirationDateTreated = payment.expirationDate
		.split('/')
		.join('')
		.replaceAll('_', '');

	const cvcTreaded = payment.cvc.split(' ').join('').replaceAll('_', '');

	const scrollUp = () => {
		window.scrollTo(0, 0);
	};

	const navigate = useNavigate();

	// FUNÇÃO PRA RETORNAR PARCELAMENTO
	function generateInstallments() {
		if (carrinho && carrinho.length === 1 && carrinho[0].cartItems.length === 1 && carrinho[0].cartItems[0].quantity === 1 && carrinho[0].cartItems[0].TIPO !== 'S') {
			const itemParcelas = carrinho[0].cartItems[0].PARCELAS
			setParcelas(itemParcelas)
		} else if (carrinho && carrinho.length > 0 && carrinho[0].cartItems[0].TIPO === 'S') {
			let maxInstallments = carrinho[0].cartItems[0].paymentPlan.maxInstallments;
			
			for (const cart of carrinho) {
				let currentPlan = cart.cartItems[0].paymentPlan;

				if (currentPlan.turnYear || new Date().getFullYear() < Number(cart.cartItems[0].paymentPlan.schoolYear.slice(0, -2))) {
					maxInstallments = maxInstallments > currentPlan.maxInstallments ? 
					currentPlan.maxInstallments : maxInstallments;
				} else {
					let currentInstallments = currentPlan.maxInstallments > (12 - new Date().getMonth()) ?
					(12 - new Date().getMonth()) : currentPlan.maxInstallments;

					maxInstallments = currentInstallments < maxInstallments ? 
					currentInstallments : maxInstallments;
				}
			}

			setParcelas(maxInstallments)
		} else {

			if (cartTotal <= 99.99) {
				setParcelas(1);
			}

			if (cartTotal >= 100 && cartTotal <= 199.99) {
				setParcelas(2);
			}

			if (cartTotal >= 200 && cartTotal <= 299.99) {
				setParcelas(3);
			}

			if (cartTotal >= 300 && cartTotal <= 399.99) {
				setParcelas(4);
			}

			if (cartTotal >= 400 && cartTotal <= 999.99) {
				setParcelas(6);
			}

			if (cartTotal >= 1000) {
				setParcelas(12);
			}
		}
	}

	// useeffect pra atualizar parcelamento de acordo com o cartTOtal
	useEffect(() => {
		generateInstallments();
	}, [cartTotal]); // eslint-disable-line react-hooks/exhaustive-deps

	// array das parcelas
	let arr = [];
	for (var i = 0; i <= parcelas; i++) {
		const divisao = cartTotalParcelado / i;
		arr.push(parseFloat(divisao.toFixed(2)));
	}
	const parcelamentoArray = arr.slice(1, arr.length);
	parcelamentoArray[0] = Number(cartTotal);

	// pegar array de paymentMethods da API
	useEffect(() => {
		const optionsListPaymentMethods = {
			method: 'GET',
			url: `${BASE_URL}/payment-methods/list-payment-methods`,
		};

		axios
			.request(optionsListPaymentMethods)
			.then(function (response) {
				setPaymentMethodIds(response.data.paymentMethods);
			})
			.catch(function (error) {
				console.error(error);
			});
	}, [paymentMethod]); // eslint-disable-line react-hooks/exhaustive-deps

	// SEND CARD PAYMENT TO API
	function sendCardPaymentAPI() {
		const getCardType = creditCardType(payment?.cardNumber.replace(/[^\d]/g, ""));
		const cardBrand = getCardType[0].type === "mastercard" ? "MASTER" :
			(getCardType[0].type === "diners-club" ? "DINERS" : (getCardType[0].type === "american-express" ? "AMEX" : getCardType[0].type.toLocaleUpperCase()));
		const cartId = carrinho[0].cartId;
		const cardNumber = payment.cardNumber.split(' ').join('').replaceAll('_', '');
		const cvc = payment.cvc.replaceAll('_', '');
		const expirationDate = payment.expirationDate.split('/');
		const expirationMonth = expirationDate[0];
		const expirationYear = expirationDate[1];
		const holder = payment.holder;
		const installments = parseInt(payment.installments);
		const deliveryDate = new Date().toISOString();

		const optionsSendCardPayment = {
			method: 'PUT',
			url: `${BASE_URL}/carts/finish-cart-by-customer/${customerId}/cart/${cartId}/store/${STORE_ID}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			data: {
				brand: cardBrand,
				cardNumber: cardNumber,
				expirationDate: `20${expirationYear}-${expirationMonth}-10`,
				cvv: cvc,
				holder: holder,
				installments: installments,
				paymentMethodId: paymentMethodId,
				acceptedTerms: acceptedTerms,
				deliveryDate: deliveryDate,
			},
			validateStatus: () => true,
		};

		scrollUp();
		setIsLoading(true);

		axios
			.request(optionsSendCardPayment)
			.then(function (response) {
				if (response.status === 200 || response.status === 204) {
					Toast.fire({
						icon: 'success',
						iconColor: '#fff',
						color: '#fff',
						background: '#55d98d',
						title: `Compra realizada com sucesso!`,
					});
					// seta informações a serem usadas na página de conclusão
					const conclusaoInfo = {
						nome: loggedUserData?.customer.name,
						email: loggedUserData?.customer.email,
						telefone: loggedUserData?.customer.cellPhone,
						cpf: loggedUserData?.customer.cpf,
						cart: carrinho,
						cartTotal: cartTotal,
						cartTotalParcelado: cartTotalParcelado,
						paymentMethod: credito,
						installments: installments,
						lastDigitsCard: cardNumberTreated?.slice(-4),
						boletoLink: response.data.finished.link,
						referenceCode: response.data.finished.referenceCode,
						saledId: response.data.finished.saleId,
						statusCode: response.data.finished.statusCode,
					};
					setCarrinhoConclusaoInfo(conclusaoInfo);
					localStorage.setItem(
						'carrinhoConclusaoInfo',
						JSON.stringify(conclusaoInfo)
					);
					// limpa bags
					const emptyBags = [];
					setCarrinho(emptyBags);
					localStorage.setItem('carrinho', JSON.stringify(emptyBags));
					navigate('/conclusao');
					setTimeout(() => navigate(0), 1000)
				} else if (response.status === 401) { tokenExpireLogout(response) } else if (response.status === 404 && response.data.error.message === "Carrinho não foi encontrado") {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `Seu carrinho foi deletado pelo administrador`,
					})
					setTimeout(() => {
						localStorage.setItem("carrinho", '[]')
						navigate("/")
						navigate(0)
					}, 2000)
				} else {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
				}

				setIsLoading(false);
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// SEND BOLETO PAYMENT TO API
	function sendBoletoPaymentAPI() {
		const cartId = carrinho[0].cartId;
		const deliveryDate = new Date().toISOString();

		const optionsSendBoletoPayment = {
			method: 'PUT',
			url: `${BASE_URL}/carts/finish-cart-by-customer/${customerId}/cart/${cartId}/store/${STORE_ID}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			data: {
				paymentMethodId: paymentMethodId,
				acceptedTerms: acceptedTerms,
				deliveryDate: deliveryDate,
			},
			validateStatus: () => true,
		};

		scrollUp();
		setIsLoading(true);

		axios
			.request(optionsSendBoletoPayment)
			.then(function (response) {
				//console.log(optionsSendBoletoPayment)
				//console.log(response.data.error);
				//console.log(response)
				if (response.status === 200 || response.status === 204) {
					Toast.fire({
						icon: 'success',
						iconColor: '#fff',
						color: '#fff',
						background: '#55d98d',
						title: `Compra realizada com sucesso!`,
					});
					// seta informações a serem usadas na página de conclusão
					const conclusaoInfo = {
						nome: loggedUserData?.customer.name,
						email: loggedUserData?.customer.email,
						telefone: loggedUserData?.customer.cellPhone,
						cpf: loggedUserData?.customer.cpf,
						cart: carrinho,
						cartTotal: cartTotal,
						cartTotalParcelado: cartTotalParcelado,
						paymentMethod: paymentMethod,
						installments: 1,
						boletoLink: response.data.finished.link,
						referenceCode: response.data.finished.referenceCode,
						saledId: response.data.finished.saleId,
						statusCode: response.data.finished.statusCode,
					};
					setCarrinhoConclusaoInfo(conclusaoInfo);
					localStorage.setItem(
						'carrinhoConclusaoInfo',
						JSON.stringify(conclusaoInfo)
					);
					// limpa bags
					const emptyBags = [];
					setCarrinho(emptyBags);
					localStorage.setItem('carrinho', JSON.stringify(emptyBags));
					navigate('/conclusao');
					setTimeout(() => navigate(0), 1000)
				} else if (response.status === 401) { tokenExpireLogout(response) }
				else if (response.status === 404 && response.data.error.message === "Carrinho não foi encontrado") {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `Seu carrinho foi deletado pelo administrador`,
					})
					setTimeout(() => {
						localStorage.setItem("carrinho", '[]')
						navigate("/")
						navigate(0)
					}, 2000)
				} else {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
				}

				setIsLoading(false);
			})
			.catch(function (error) {
				console.error({ error });
				setIsLoading(false);
			});
	}

	// SUBMIT CARD PAYMENT
	const handleSubmitCardPayment = () => {
		if (
			!payment.installments ||
			!payment.holder ||
			cardNumberTreated.length < 15 ||
			expirationDateTreated.length < 4 ||
			cvcTreaded.length < 3
		) {
			Swal.fire({
				icon: 'error',
				iconColor: '#ff6363',
				title: 'Formulário Incompleto',
				text: 'Preencha todos os campos do formulário para prosseguir.',
				confirmButtonColor: '#1e3799',
				confirmButtonText: 'OK',
			});
		} else {
			setPaymentMethod(credito);
			sendCardPaymentAPI();
		}
	};

	const handleSubmitFreePayment = () => {
		setPaymentMethod('gratuito');
		sendBoletoPaymentAPI();
	}

	// SUBMIT BOLETO PAYMENT
	const handleSubmitBoletoPayment = () => {
		setPaymentMethod(boleto);
		sendBoletoPaymentAPI();
	};

	// SUBMIT PAYMENT HANDLER (usado no botão)
	const handleSubmitPayment = () => {
		if (acceptedTerms === 'enabled') {
			paymentMethod === credito
				? handleSubmitCardPayment()
				: paymentMethod === 'gratuito' ? handleSubmitFreePayment() : handleSubmitBoletoPayment();
		} else {
			Swal.fire({
				icon: 'error',
				iconColor: '#ff6363',
				title: 'Termos não Aceitos',
				text: 'É necessário aceitar os termos de uso para prosseguir com o pagamento.',
				confirmButtonColor: '#1e3799',
				confirmButtonText: 'OK',
			});
		}
		setAcceptedTerms('disabled');
	};

	const value = {
		payment,
		setPayment,
		paymentMethod,
		setPaymentMethod,
		boleto,
		credito,
		parcelas,
		parcelamentoArray,
		handleSubmitPayment,
		paymentMethodIds,
		setPaymentMethodId,
		isLoading,
		cardNumberTreated,
		acceptedTerms,
		setAcceptedTerms,
	};
	return (
		<PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
	);
};
