import React, { useState, useContext } from 'react';
import './Newsletter.styles.scss';
import ChatIcon from '../../../assets/Icons/icon-chat.svg';
import TopIcon from '../../../assets/Icons/icon-top.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GlobalContext } from '../../../contexts/GlobalContext';
import Swal from 'sweetalert2'

const Newsletter = () => {
	const scrollUp = () => {
		window.scrollTo(0, 0);
	};
	const navigate = useNavigate();

	const { BASE_URL, STORE_ID } = useContext(GlobalContext);
	const [email, setEmail] = useState('');

	function handleNewsletterChange(value) {
		setEmail(value);
	}

	function handleSubmitNewsletter(e) {
		e.preventDefault();

		const options = {
			method: 'POST',
			url: `${BASE_URL}/newsletters/create-newsletter`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				storeId: STORE_ID,
				email: email,
			},
		};

		//console.log(options);

		axios
			.request(options)
			.then(function (response) {
				//console.log(response.data);
				Swal.fire({
					icon: 'success',
					title: 'Email cadastrado com sucesso',
					text: 'Email cadastrado com sucesso ao newsletter. Verifique o seu email.',
					confirmButtonColor: '#1e3799',
					confirmButtonText: 'OK',
				});
			})
			.catch(function (error) {
				console.error(error);
				Swal.fire({
					icon: 'error',
					iconColor: '#ff6363',
					title: 'Email já Cadastrado',
					text: 'Esse email já foi cadastrado no newsletter, tente outro.',
					confirmButtonColor: '#1e3799',
					confirmButtonText: 'OK',
				});
			});
	}

	return (
		<>
			<div className="bottom-wave"></div>
			<div className="newsletter-wrapper">
				<div className="newsletter-container">
					<div className="newsletter-cta">
						<div className="newsletter-cta-top">NEWSLETTER</div>
						<div className="newsletter-cta-bottom">
							Fique por dentro das novidades!
						</div>
					</div>
					<div className="newsletter-sub-box">
						<form
							className="newsletter-search-form"
							onSubmit={handleSubmitNewsletter}>
							<div className="relative">
								<div className="newsletter-search-icon">
									<span className="material-symbols-outlined">send</span>
								</div>
								<input
									type="search"
									id="search-newsletter"
									className="newsletter-search-field"
									placeholder="Cadastre seu e-mail"
									required
									onChange={({ target: { value } }) =>
										handleNewsletterChange(value)
									}
								/>
								<button type="submit" className="newsletter-btn-sub">
									Assinar
								</button>
							</div>
						</form>
					</div>
					<div className="newsletter-btns">
						<div className="newsletter-btn-contact">
							<button
								type="button"
								className="btn-contact"
								onClick={() => navigate('/atendimento')}>
								<img src={ChatIcon} alt="" />
							</button>
						</div>
						<div className="newsletter-btn-top">
							<button type="button" className="btn-top" onClick={scrollUp}>
								<img src={TopIcon} alt="" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Newsletter;
