import React from 'react';
import './Spinner.styles.scss';
import Loader from '../../assets/Logo/logo-4.png';

const Spinner = () => {
	return (
		<>
			<div className="loading-wrapper">
				<div className="loading">
					<img src={Loader} alt="" />
				</div>
			</div>
		</>
	);
};

export default Spinner;
