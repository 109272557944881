import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const STORE_ID = process.env.REACT_APP_STORE_ID;

export const CategoryContext = createContext(null);

export const CategoryProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false);
	// CATEGORY LIST STATE
	const [categories, setCategories] = useState([]);

	// FETCH CATEGORY LIST FROM API
	const optionsListCategories = {
		method: 'GET',
		url: `${BASE_URL}/categories/list-category-by-store-id/${STORE_ID}`,
		params: {field: 'visible', filter: 'enabled'}
	};

	useEffect(() => {
		axios
			.request(optionsListCategories)
			.then(function (response) {
				setCategories(response.data.categories.filter(category => category.status !== "disabled"));
			})
			.catch(function (error) {
				console.error(error);
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// LISTA CATEGORY BY ID
	const [thisProductCategory, setThisProductCategory] = useState();

	function listCategoryById(categoryId) {
		const optionsListCategoryById = {
			method: 'GET',
			url: `${BASE_URL}/categories/list-category-by-id-and-store-id/${categoryId}/store/${STORE_ID}`,
		};

		axios
			.request(optionsListCategoryById)
			.then(function (response) {
				setThisProductCategory(response.data.category);
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	// PRODUCT LIST STATE
	const isMobileDevice = useMediaQuery({
		query: '(min-device-width: 480px)',
	});

	const [itemListByCategory, setItemListByCategory] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const subproductsPerPage = isMobileDevice ? 9 : 6;
	const startIndex = (page - 1) * subproductsPerPage;

	useEffect(() => {
		const items = itemListByCategory;
		if(items.length > 0){
			setTotalPages(Math.ceil(items.length / subproductsPerPage));
		}
	}, [itemListByCategory, subproductsPerPage])

	// FECTH PRODUCT LIST BY CATEGORY ID
	function listProductByCategory(categorySlug, seriesTOTVSID) {
		const optionslistProductByCategory = {
			method: 'POST',
			url: `${BASE_URL}/products/list-product-by-category-slug/${categorySlug}/store/${STORE_ID}/visible/enabled`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: { seriesTOTVSID: seriesTOTVSID },
		};

		axios
			.request(optionslistProductByCategory)
			.then(function (response) {
				setItemListByCategory(response.data.products);
				//console.log("itemListByCategory", response.data.products)
				const items = response.data.products;
				//console.log("items: ", items)
				setTotalPages(Math.ceil(items.length / subproductsPerPage));
			})
			.catch(function (error) {
				console.error(error);
			});
	}

	const items = itemListByCategory;
	const pages = [...Array(totalPages).keys()].map((num) => num + 1);

	const selectedProducts = items.slice(
		startIndex,
		startIndex + subproductsPerPage
	);

	const scrollUp = () => {
		window.scrollTo(0, 0);
	};

	const handlePagination = (num) => {
		setPage(num);
		scrollUp();
	};

	const handlePrevPage = () => {
		setPage(page - 1);
		scrollUp();
	};
	const handleNextPage = () => {
		setPage(page + 1);
		scrollUp();
	};

	const handleFirstPage = () => {
		setPage(1);
		scrollUp();
	};

	let lastPage = pages[pages.length - 1];

	const handleLastPage = () => {
		setPage(lastPage);
		scrollUp();
	};

	// ICON VALUES MAP
	const categoryIcons = {
		agenda: 'book',
		apostilhas: 'auto_stories',
		camisas: 'laundry',
		canecas: 'local_cafe',
		cursos: 'library_books',
		'e-books': 'style',
		eventos: 'celebration',
		fardamento: 'checkroom',
		'material-didatico': 'draw',
		papelaria: 'note_alt',
		squeezes: 'water_drop',
		outros: 'grid_view',
		'categoria-nova': 'grid_view',
	};

	// PROVIDED VALUES
	const value = {
		categories,
		categoryIcons,
		listProductByCategory,
		itemListByCategory,
		setItemListByCategory,
		isLoading,
		setIsLoading,
		listCategoryById,
		thisProductCategory,
		page,
		pages,
		selectedProducts,
		setPage,
		handlePagination,
		handlePrevPage,
		handleNextPage,
		handleFirstPage,
		handleLastPage,
	};
	return (
		<CategoryContext.Provider value={value}>
			{children}
		</CategoryContext.Provider>
	);
};
